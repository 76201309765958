import React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import { fetchWrapper } from 'utils/apihandlers';
import InputComp from 'components/InputComp'
import TextboxComp from 'components/TextboxComp'
import Button from 'components/Button'
import SmallButton from 'components/SmallButton'
import HorizontalLine from 'components/horizontalLine/HorizontalLine';
import { PDFDocument, rgb, PageSizes, StandardFonts, drawText } from "pdf-lib";
import { formatDollar, getLine, formatDate } from 'utils/helperfunctions';
import download from 'downloadjs'
import ReadNoticesComp from "components/readNoticesComp"
import { parse } from 'path';
// import ScrapeImageProp from "components/scrapeImageProp"

interface Prop{
    countyname: string;
    openModal: (header: any, message: any, progress?: any)=>void;
    properties: any;
    setproperties: (p:any)=>void;
    setloading:(x:boolean)=>void;
}
const DisplayListingComps : React.FC<Prop>=({countyname, properties, setproperties, openModal, setloading})=>{
    const [ legaldescription, setlegaldescription ] = useState("")
    
    const [ address, setaddress ] = useState("")
    const [ auctiondate, setauctiondate ] = useState("")
    const [ searchownername, setsearchownername ] = useState("")
    const [ city, setcity ] = useState("")
    // const [ loading, setloading ] = useState(false)
    // const [ loadingdeed, setloadingdeed ] = useState(false)
    
    const [ selectedprop, setselectedprop ] = useState(-1)
    const [ selectedIdx, setselectedIdx ] = useState(-1)
    const [ propid, setpropid ] = useState(-1)
    const [ deeddownloadprogressmsg, setdeeddownloadprogressmsg ] = useState("")

    const [ showmissingdeeds, setshowmissingdeeds ] = useState(false)
    
    
    const [ operationslog, setoperationslog ] = useState("")
    
    const [ foreclosuretype, setforeclosuretype ] = useState("mortgage")    
    const [ lienamount, setlienamount ] = useState("")
    const [ lienyear, setlienyear ] = useState("")
    const [ assessedValue, setassessedValue ] = useState("")
    const [ improvementarea, setimprovementarea ] = useState("")
    const [ ownername, setownername ] = useState("")
    const [ propertytype, setpropertytype ] = useState("")
    
    const [ deednumber, setdeednumber ] = useState("")
    const [ deeddate, setdeeddate ] = useState("")
    const [ propertyid, setpropertyid ] = useState("")
    
    const [ propertylink, setpropertylink ] = useState("")
    const [ propdesc, setpropdesc ] = useState("")
    const [ street, setstreet ] = useState("")
    const [ addrcity, setaddrcity ] = useState("")
    const [ state, setstate ] = useState("")
    const [ zip, setzip ] = useState("")
    const [ yearbuilt, setyearbuilt ] = useState("")
    

    const [ trusteename, settrusteename ] = useState("")
    const [ trusteephone, settrusteephone ] = useState("")
    const [ searchtrusteename, setsearchtrusteename ] = useState("")
    const [ searchstreetname, setsearchstreetname ] = useState("")
    
    
    const [ favorite, setfavorite ] = useState(false)
    const [ searchfavorites, setsearchfavorites ] = useState(false)    
    const [ imageurl, setimageurl ] = useState<any>(null)
    const [ currentimageidx, setcurrentimageidx ] = useState(-1)
    
    const [ notice, setnotice ] = useState("")
    const [ deedsearchtext, setdeedsearchtext ] = useState("")
    const [ beds, setbeds ] = useState("")
    const [ baths, setbaths ] = useState("")
    const [ noticelink, setnoticelink ] = useState("")

    const [ parsedtext, setparsedtext ] = useState("")   

    const [ noticeFileName, setnoticeFileName ] = useState("")
    const [ uploadednoticeFileName, setuploadedNoticeFileName ] = useState("")
    const [ uploadednoticeFile, setuploadedNoticeFile ] = useState<any>(null)
        

    const [ searchval, setsearchval ] = useState("")
    const [ replaceval, setreplaceval ] = useState("")
    const selectProp = (idx: any) => {
        setparsedtext("")
        setselectedIdx(idx)
        setaddress(`${properties[idx].street}, ${properties[idx].city}, ${properties[idx].state}, ${properties[idx].zip}`)
        setlegaldescription(`${properties[idx].legaldescription}`)
        setlienamount(properties[idx].defaultamount?properties[idx].defaultamount:"")
        setlienyear(properties[idx].lienyear?properties[idx].lienyear:"")
        setpropdesc(properties[idx].description?properties[idx].description:"")
        setassessedValue(properties[idx].appraisedvalue)
        setimprovementarea(properties[idx].improvementarea)
        setownername(properties[idx].ownername?properties[idx].ownername:"")
        setpropertytype(properties[idx].type)
        setdeednumber(properties[idx].deednumber)
        setdeeddate(properties[idx].deeddate)
        setpropertylink(properties[idx].propertyid?`http://www.collincad.org/propertysearch?prop_id=${properties[idx].propertyid}`:"http://www.collincad.org/propertysearch")
        setpropertyid(properties[idx].propertyid)
        getAddress(`${properties[idx].street}, ${properties[idx].city}, ${properties[idx].state}, ${properties[idx].zip} `)
        settrusteename(properties[idx].trusteename?properties[idx].trusteename:"")
        settrusteephone(properties[idx].trusteephone?properties[idx].trusteephone:"")
        setfavorite(properties[idx].favorite)
        setyearbuilt(properties[idx].yearbuilt)
        setdeedsearchtext(properties[idx].deednumber)
        setnotice(properties[idx].foreclosurenotice?properties[idx].foreclosurenotice:"")
        setbeds(properties[idx].beds?properties[idx].beds:"")
        setbaths(properties[idx].baths?properties[idx].baths:"")
        setforeclosuretype(properties[idx].foreclosuretype)
        setnoticelink(properties[idx].noticelink)
        setnoticeFileName(properties[idx].noticefiletag?properties[idx].noticefiletag:"")
		setuploadedNoticeFile(null)
		setuploadedNoticeFileName("")
        if(properties[idx].images && properties[idx].images.length>0){
          let response: any = showImage(properties[idx].images[0])
          setcurrentimageidx(0)
        }else{
          setimageurl(null)
          setcurrentimageidx(-1)
        }

    }

    const showpreviousimage = async () => {
      if((currentimageidx-1)>=0){
        await showImage(properties[selectedIdx].images[currentimageidx-1])
        setcurrentimageidx(currentimageidx-1)
      }
    }

    const shownextimage = async () => {
      if((currentimageidx+1)<properties[selectedIdx].images.length){
        await showImage(properties[selectedIdx].images[currentimageidx+1])
        setcurrentimageidx(currentimageidx+1)
      }
    }

    const showImage = async (imagename: any) => {
      let body = {
        imagename:imagename
      }
      // console.log(`body : ${JSON.stringify(body)}`)
      try{
        var response = await fetchWrapper.post("/api/admin/showimage", {imagename: imagename}).then((r: any)=>r)
        setimageurl(response.data.url)
      }catch(error){}

      
    }

    const getAddress = (address: any) => {
      const regex = /([\dA-Za-z\s]+)[,]?[\s+]?([A-Za-z\s]+)[,]?[\s+]?([A-Za-z]{2})[,]?[\s+]?(\d{5})/i;
      // const regex = /([0-9A-Za-z\s]+),\s*([A-Za-z\s]+),\s*([A-Z]{2}),\s*(\d{5})/;
      const match = address.match(regex);
      let info: any = {}
      if (match) {
          setstreet(match[1].toUpperCase())
          setaddrcity(match[2].toUpperCase())
          setstate(match[3].toUpperCase())
          setzip(match[4].toUpperCase())
          // console.log(`here ${match.join("-")}`)
      }else{
        setstreet("")
        setaddrcity("")
        setstate("")
        setzip("")
      }
      
    }

    const changeaddress = (e: any) => {
        let address = e.target.value
        setaddress(address)
        address +=" "
        getAddress(address)
    }

    const updatepropinfo = async () => {
        setloading(true)
        
        var response = await fetchWrapper.post("/api/foreclosureapi/updateforeclosureprop", {foreclosureid:properties[selectedIdx].foreclosureid, lienamount: lienamount, lienyear: lienyear, description: propdesc, appraisedvalue: assessedValue, improvementarea: improvementarea, ownername: ownername, deednumber: deednumber, deeddate: deeddate, propertylink: propertylink, street: street, city: addrcity, state: state, zip: zip, trusteename:trusteename, trusteephone:trusteephone, favorite: favorite, type: propertytype, legaldescription: legaldescription, yearbuilt: yearbuilt, beds: beds, baths: baths, propertyid: propertyid, defaultamount: lienamount, foreclosuretype: foreclosuretype, noticelink:noticelink
        }).then(r=>r)
        setloading(false)
  
        properties[selectedIdx].propertyid = propertyid
        properties[selectedIdx].street = street
        properties[selectedIdx].city = addrcity
        properties[selectedIdx].state = state
        properties[selectedIdx].zip = zip
        properties[selectedIdx].defaultamount = lienamount
        properties[selectedIdx].lienamount = lienamount
        properties[selectedIdx].lienyear = lienyear
        properties[selectedIdx].description= propdesc
        properties[selectedIdx].appraisedvalue = assessedValue
        properties[selectedIdx].improvementarea = improvementarea
        properties[selectedIdx].ownername = ownername
        properties[selectedIdx].type = propertytype
        properties[selectedIdx].deednumber = deednumber
        properties[selectedIdx].deeddate = deeddate
        properties[selectedIdx].trusteename = trusteename
        properties[selectedIdx].trusteephone = trusteephone
        properties[selectedIdx].favorite = favorite
        properties[selectedIdx].yearbuilt = yearbuilt
        properties[selectedIdx].legaldescription = legaldescription
        properties[selectedIdx].beds = beds
        properties[selectedIdx].baths = baths
        properties[selectedIdx].foreclosuretype = foreclosuretype
        properties[selectedIdx].noticelink = noticelink
        setproperties(properties)
      }

      const insertdocblock = () => {
        let deedsearchtext = `[{
          "header":"",
          "documentNumber":"",
          "recordDate":"",
          "grantor":"",
          "grantee":"",
          "legaldesc":"",
          "valididx":,
          "documentStatus":"",
          "numberOfPages":"",
          "bookVolumePage":"",
          "instrumentDate":"",
          "description":"",
          "deeddocfileid":"",
          "doclinktag":"",
          "consideration":"[]"
        }]`
        setdeedsearchtext(deedsearchtext)
      }
  
  
      const createtitlePDF = async (foreclosureid: any) => {
        // openModal("Message", "Downloading deed file", true)
  
        let titleinfo: any = []
        try{
          titleinfo = JSON.parse(deedsearchtext)
        }catch(error){
          console.log(`${error}`)
          
          openModal("Warning", "Load deed document before proceeding!", false)
          return
        }
        if(titleinfo.length<1){
          openModal("Warning", "Get deed document before proceeding!", false)
          return 
        }
  
        try{
          
          await makeChainOfTitlePdf(titleinfo, address, foreclosureid)
        }catch(error){
          // openModal("Error", "Deed file does not exist", false)
          openModal("Error", `${error}`, false)
        }
      }
  
      const loadtitlefile = async () =>{
        try{
          let titleinfo: any = await fetchWrapper.post("/api/admin/foreclosureapi/loaddeeddocumenttext",{foreclosureid: properties[selectedIdx].foreclosureid}).then(info=> info)
          
          let txt: any = Buffer.from(titleinfo.data.data).toString()
  
          setdeedsearchtext(txt)
          
        }catch(error){
          openModal("Error", "Deed file does not exist", false)
        }
      }

      const DrawText = (
        page: any,
        font: any,
        value: string,
        xpos: number,
        ypos: number,
        fontSize: number
      ) => {
        const color = rgb(0, 0, 0)
        page.drawText(value, {
          x: xpos,
          y: ypos,
          size: fontSize,
          font: font,
          color: color,
        })
      }
  
      const DrawLine = (page: any, font: any, ypos: any) => {
        let txt = `____________________________________________________________________________`
        DrawText(page, font, txt, 50, ypos+7, 12)
      }
  
      const writeMultipleLines = (page: any, font: any, xpos: any, ypos: any, fontsize: any, linespacing: any,  text: any) => {
  
        let getnextline = true
        let line_idx = 0
        let lineswritten = 0
        while(getnextline){
          let line = getLine(text, 60)
          DrawText(page, font, `${line}`, xpos, ypos-line_idx*linespacing, fontsize)
          lineswritten++
          line_idx++
          if(line.length==text.length)
          {
            getnextline = false
            break
          }
          text = text.substring(line.length)
        }
        return lineswritten
        // DrawLine(page, font, ypos-20*4-12*line_idx)
      }

      const makeDeedDocBlock = async (page: any, font: any, blockinfo: any, ypos: any, pdfDoc: any) => {
      
        DrawText(page, font, blockinfo.header, 50, ypos, 16)
        DrawLine(page, font, ypos-10)
        DrawText(page, font, `Document#: ${blockinfo.documentNumber}`, 50, ypos-20*1, 10)
        let link = `https://collin.tx.publicsearch.us/doc/${blockinfo.doclinktag}`
        DrawText(page, font, `Date: ${blockinfo.instrumentDate}`, 50, ypos-15-20*1, 10)
        DrawText(page, font, `Recorded: ${blockinfo.recordDate}`, 175, ypos-15-20*1, 10)
        DrawText(page, font, `Book & Page: ${blockinfo.bookVolumePage}`, 400, ypos-10-20*1, 10)
        var lineswritten = writeMultipleLines(page, font, 50, ypos-12-19*2, 12, 13, `Grantee: ${blockinfo.grantee}`)
        var lineswritten2 = writeMultipleLines(page, font, 50, ypos-12-17*(3+lineswritten-1), 12, 13, `Grantor: ${blockinfo.grantor}`)
        lineswritten+=lineswritten2-1
        if(blockinfo.consideration)
        { 
          let consideration
          try{
            consideration = JSON.parse(blockinfo.consideration)
            // console.log(`here len ${consideration}`)
          
            if(consideration.length>0 && consideration[0]!="N/A"){
              // console.log(`blockinfo.consideration : ${JSON.stringify(consideration)}`)
              
              DrawText(page, font, `Balance: ${formatDollar(consideration[0])}`, 50, ypos-12-17*(3+lineswritten), 12)
            }
          }catch(error){
            console.log(`error ${error}, ${blockinfo.consideration}`)
          }
          
        }
      }

      const makeChainOfTitlePdf = async (titleinfo: any, propertyaddress: any, foreclosureid: any) => {

        const pdfDoc = await PDFDocument.create();
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        let page = pdfDoc.addPage(PageSizes.Letter)
        let legal = titleinfo[0].legaldesc.split('Reference')[0]
        DrawText(page, helveticaFont, `Address: ${propertyaddress}`, 50, 715, 18)
        DrawText(page, helveticaFont, `Legal: ${legal}`, 50, 695, 14)
        DrawText(page, helveticaFont, `Owner: ${ownername}`, 50, 675, 14)
        DrawText(page, helveticaFont, `County: ${countyname}`, 50, 655, 14)
        
        let startY = 625
        let idx = 0
        let pagenumber = 0
        // console.log(`here total pages: ${titleinfo.length}`)
        
        for(let i=0; i<titleinfo.length; i++){
          // console.log(`here making page ${i}`)
          
          if((pagenumber==0 && i>0 && i%4==0)||(pagenumber>0 && idx>1 && idx%5==0)){
            page = pdfDoc.addPage(PageSizes.Letter)
            idx = 0
            startY = 700
            pagenumber++
          }
          await makeDeedDocBlock(page, helveticaFont, titleinfo[i], startY-idx*130, pdfDoc)
          idx++
        }
  
        const pdfBytes = await pdfDoc.save()
  
        var pdfblob = new Blob([pdfBytes], { type: "application/pdf" })
        let filename = address.split(" ").join("_")
        filename = foreclosureid
        download(pdfblob, `${filename}.pdf`)
  
      }

	  const parsenoticeimage = async () => {
	
			openModal("Uploading and reading file", "", true)
	
			var formData = new FormData()
	
			if (! uploadednoticeFile ) {
			openModal("Error", "Please select an notice file before proceeding.", false)
				return;
			}
	
			formData.append("file", uploadednoticeFile)
	
			let response = await fetchWrapper.postFile('/api/foreclosureapi/uploadandparsenoticeimage', formData)
			openModal("Done!", "Notice parsed", false)
			setuploadedNoticeFile(null)
			setuploadedNoticeFileName("")
			setnotice(response.text)
		}

	const noticeFileChange = (event: any) => {
        if (!event.target.files || event.target.files[0] == undefined) {
            setuploadedNoticeFileName("")
            setuploadedNoticeFile(null)
        } else {
            if(!event.target.files[0].type.includes('pdf')){
                openModal("Warning", "Invalid file type, only pdf files are allowed.")
                return
            }
            setuploadedNoticeFileName(event.target.files[0].name)
            setuploadedNoticeFile(event.target.files[0])
        }
    }

      useEffect(()=>{
        setselectedIdx(-1)
      },[properties])


  return(
    <MainContainer>
    <ButtonContainer>

        <ReadNoticesComp text="Read Selected Notice"  county={countyname} properties={properties} selectedIdx={selectedIdx} singleprop={true} setloadingdeed={setloading} setpropid={setpropid} setdeeddownloadprogressmsg={setdeeddownloadprogressmsg} setoperationslog={setoperationslog} openModal={openModal} smallbutton={true} width={100} setparsedtext={setparsedtext}/>

        <ReadNoticesComp text="Read All Notices"  county={countyname} properties={properties} selectedIdx={selectedIdx} singleprop={false} setloadingdeed={setloading} setpropid={setpropid} setdeeddownloadprogressmsg={setdeeddownloadprogressmsg} setoperationslog={setoperationslog} openModal={openModal} smallbutton={true} width={125} setparsedtext={setparsedtext} />

    </ButtonContainer>

        {parsedtext!==""?<>
          <TextboxComp placeholder='Parsed Notice' id='parsednoticetb' value={parsedtext} onChange={(e: any)=>{}} rows={10} cols={50} width={500}/>
        </>
        :<></>}

    
        <div style={{margin:"5px 0"}}>#of properties: {properties.length}</div>

        <div style={{ width: '90%'}}>
            <Prop style={{ backgroundColor: 'lightgray', fontWeight: 700, padding: "0 15px 0 0" }}>
            <Item style={{ flex: 0.25 }}>#</Item>
            <Item style={{ flex: 1 }}>ID</Item>
            <Item style={{ flex: 2 }}>Address</Item>
            <Item style={{ flex: 1 }}>Auction Date</Item>
            <Item style={{ flex: 1 }}>File Date</Item>
            <Item style={{ flex: 2 }}>Appraised Value</Item>
            <Item style={{ flex: 0.5 }}>Year Built</Item>
            <Item style={{ flex: 1 }}>Lien</Item>
            <Item style={{ flex: 0.5 }}>Lien Year</Item>
            <Item style={{ flex: 0.75 }}>Sq. Ft.</Item>
            <Item style={{ flex: 0.75 }}>Prop Type</Item>
            </Prop>
        </div>
        <div style={{ width: '90%',  maxHeight: '250px', overflowY: 'scroll' }}>

            {properties.map((value: any, idx: any) => (
            (!showmissingdeeds ||(showmissingdeeds && !properties[idx].deedexists)?
            <Prop
                style={{
                color: idx == selectedIdx ? '#bf40bf' : '#000000'
                }}
                key={`userkey${idx}${showmissingdeeds}`}
                onClick={() => {
                selectProp(idx)
                }}
            >
                <Item key={`keyprop_${idx}_1`} style={{ flex: 0.25 }}>{idx+1}</Item>
                <Item key={`keyprop_${idx}_id`} style={{ flex: 1 }}>{value.foreclosureid.substring(0,7)}</Item>
                <Item key={`keyprop_${idx}_street`} style={{ flex: 2 }}>{`${value.street}, ${value.city}, ${value.zip}`}</Item>
                <Item key={`keyprop_${idx}_aucdt`} style={{ flex: 1 }}>{value.auctiondate}</Item>
                <Item key={`keyprop_${idx}_fldt`} style={{ flex: 1 }}>{value.filedate}</Item>
                <Item key={`keyprop_${idx}_aprval`} style={{ flex: 2 }}>{value.appraisedvalue}</Item>
                <Item key={`keyprop_${idx}_yearbuilt`} style={{ flex: 0.5 }}>{value.yearbuilt}</Item>
                <Item key={`keyprop_${idx}_defaultamount`} style={{ flex: 1 }}>{formatDollar(value.defaultamount)}</Item>
                <Item key={`keyprop_${idx}_lienyear`} style={{ flex: 0.5 }}>{value.lienyear}</Item>
                <Item key={`keyprop_${idx}_improvementarea`} style={{ flex: 0.75 }}>{value.improvementarea}</Item>
                <Item key={`keyprop_${idx}_type`} style={{ flex: 0.75 }}>{value.type}</Item>
            </Prop>:<></>
            )
            ))}
        </div>
        <Row>
        <Column>

        <div style={{margin:"10px 0", width: '95%'}}>
            {
                properties.length>0&&selectedIdx>=0?<>
                <Header style={{fontSize:'16px'}}>Details</Header>
                <InputComp id="foreclosuretype"
                value = {foreclosuretype}
                onChange={(e: any)=>setforeclosuretype(e.target.value)}
                placeholder='foreclosure type (mortgage or hoa)'
                />
                
                <ButtonContainer>
                    <InputComp id="ownerid" value={ownername} onChange={(e:any)=>setownername(e.target.value)} placeholder={"owner name"} />
                    <InputComp id="proptypeid" value={propertytype} onChange={(e:any)=>setpropertytype(e.target.value)} placeholder={"property type"} />

                </ButtonContainer>
                <InputComp id="legaldescid" value={legaldescription} onChange={(e)=>setlegaldescription(e.target.value)} placeholder={"legal description"} width={250}/>


                    <InputComp id="addressid" value={address} onChange={changeaddress} placeholder={"address(street, city, state, zip)"} width={250}/>
                    <InputComp id="streetid" value={street} onChange={(e:any)=>setstreet(e.target.value)} placeholder={"street"} width={250}/>

                <ButtonContainer>
                    <InputComp id="cityid" value={addrcity} onChange={(e:any)=>setaddrcity(e.target.value)}  placeholder={"city"} width={150}/>
                    <InputComp id="stateid" value={state} onChange={(e:any)=>setstate(e.target.value)}  placeholder={"state"} width={50}/>

                    <InputComp id="zipid" value={zip} onChange={(e:any)=>setzip(e.target.value)} placeholder={"zip"} width={75}/>

                    <InputComp id="yearbuiltid" value={yearbuilt} onChange={(e:any)=>setyearbuilt(e.target.value)} placeholder={"year built"} width={75}/>

                </ButtonContainer>
                <ButtonContainer>
                    <InfoHeader>notice url</InfoHeader>
                    <InfoItem><Link href ={properties[selectedIdx].noticelink} target="_blank"> &nbsp;&nbsp;{`notice link`} </Link></InfoItem>
                    <InputComp id="noticelinkid" placeholder="Notice link" value={noticelink} onChange={(e: any)=>setnoticelink(e.target.value)} />
                </ButtonContainer>

                {/*<ButtonContainer>
                    <InfoHeader>unpaid property tax</InfoHeader>
                    <InfoItem><Link href ={`https://taxpublic.collincountytx.gov/Search/Results?Query.SearchField=1&Query.SearchText=${properties[selectedIdx].propertylink?properties[selectedIdx].propertylink.split("prop_id=")[1]:""}&Query.SearchAction=&Query.PropertyType=&Query.PayStatus=Unpaid`} target="_blank"> search property tax </Link></InfoItem>
                </ButtonContainer>

                <ButtonContainer>
                    <InfoHeader>deed search</InfoHeader>
                    <InfoItem><Link href ={`https://collin.tx.publicsearch.us/results?department=RP&recordedDateRange=18930107%2C20230811&searchOcrText=false&searchType=quickSearch&searchValue=${properties[selectedIdx].ownername}`} target="_blank"> &nbsp;&nbsp;{`https://collin.tx.publicsearch.us/`} </Link></InfoItem>
                </ButtonContainer>
                */}
                <ButtonContainer>
                    <InputComp id="deednumberid" value={deednumber} onChange={(e:any)=>setdeednumber(e.target.value)} placeholder={"deed number"} width={175}/>

                    <InputComp id="deeddateid" value={deeddate} onChange={(e:any)=>setdeeddate(e.target.value)} placeholder={"deed date"} width={100} />
                </ButtonContainer>
                

                <ButtonContainer>
                    <InputComp id="proplinkid" value={propertyid} onChange={(e: any)=>setpropertyid(e.target.value)} placeholder={"county prop id"} width={100} />&nbsp;&nbsp;
                </ButtonContainer>

                <ButtonContainer>

                    <ButtonContainer>
                        <InputComp value={lienamount} onChange={(e:any)=>setlienamount(e.target.value)} id={"lienamountid"} placeholder="lien amount" width={100}/>
                        <InputComp value={lienyear} onChange={(e:any)=>setlienyear(e.target.value)} id={"lienyearid"} placeholder="lien year" width={75}/>
                    </ButtonContainer>

                    <ButtonContainer>
                        <InputComp value={assessedValue} onChange={(e:any)=>setassessedValue(e.target.value)} id={"assessedvalueid"} placeholder="(year) appraised value" width={175}/>
                        <InputComp value={improvementarea} onChange={(e:any)=>setimprovementarea(e.target.value)} id={"improvementid"} placeholder="improvement area" width={100}/>
                        <InputComp value={beds} onChange={(e:any)=>setbeds(e.target.value)} id={"beds"} placeholder="beds" width={50}/>
                        <InputComp value={baths} onChange={(e:any)=>setbaths(e.target.value)} id={"bathsid"} placeholder="baths" width={50}/>
                    </ButtonContainer>
                </ButtonContainer>
                <ButtonContainer>
                    <InputComp value={trusteename} onChange={(e:any)=>settrusteename(e.target.value)} id={"trusteenameid"} placeholder="trustee name" width={100}/>
                    <InputComp value={trusteephone} onChange={(e:any)=>settrusteephone(e.target.value)} id={"trusteephoneid"} placeholder="trustee phone" width={100}/>
                    
                    <InputComp value={noticeFileName} onChange={(e:any)=>{}} id={"noticeFileNameid"} placeholder="Notice FileName" width={100}/>
                </ButtonContainer>
                <TextboxComp id="tb_desc" value={propdesc} onChange={(e:any)=>setpropdesc(e.target.value)} rows={5} cols={50} width={300} placeholder="property description"/>
                <Button text="update" handleClick={updatepropinfo} />

                </>:<></>
            }
        </div>
        </Column>
        <Column style={{height:"100%", borderLeft:'1px solid black'}}>
            {properties.length>0 && selectedIdx>=0?
            
            // properties[selectedIdx].images && properties[selectedIdx].images.length>0?
            <>
            <TextboxComp id="noticeinfoid" placeholder="foreclosure notice" value={notice} onChange={(e: any)=> setnotice(e.target.value)} cols={50} rows={50} width={350} height={300} />

            <HorizontalLine />
            <div style={{width: "100%"}}>
                <TextboxComp id="deedsearchtextid" placeholder="deed search text" value={deedsearchtext} onChange={(e: any)=> setdeedsearchtext(e.target.value)} width={350} cols={50} rows={50} height={300}/>
                <ButtonContainer>

                <SmallButton text="load title file" handleClick={loadtitlefile} />
                <SmallButton text="create pdf" handleClick={()=>createtitlePDF(properties[selectedIdx].foreclosureid)} />
                <SmallButton text="insert doc block" handleClick={()=>insertdocblock()} />
                </ButtonContainer>
            </div>
            <HorizontalLine />

            <Header style={{fontSize:'16px'}}>Images</Header>
            {`image: ${currentimageidx+1} / ${properties[selectedIdx].images.length}`}
            <BasicImage>
                <img src = {imageurl} style={{width:"90%", height: "90%"}} />
            </BasicImage>
            <ButtonContainer>
                <SmallButton text="prev" handleClick={showpreviousimage} />
                <SmallButton text="next" handleClick={shownextimage} />
            </ButtonContainer>

            {countyname.includes("Travis")?
			<>
              	<Span >
					<UploadInput type="file" id="inputfilename"
						onChange={noticeFileChange}
					/>
					<Label htmlFor='inputfilename' style={{ padding:"10px", color:"#33813B", backgroundColor:"#eee"}}>Select Notice File </Label>
					{/* <SmallButton text="upload and parse" handleClick={parsenoticetext} /> */}
					<SmallButton text="parse Image" handleClick={parsenoticeimage} backgroundColor={"#813379"}/>
					</Span>
				<div style={{padding:"7px", color: "#813379"}}>{uploadednoticeFileName}</div>
			</>
            :
            
            <></>}

            </>
            
            :<></>
            }

        </Column>
        </Row>
    </MainContainer>
  );
}


const MainContainer = styled.div`
  margin: 25px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  width: 90%;
  color: #000000;
  min-height: 50vh;
`
const Column = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
`
const Header = styled.div`
  display: flex;
  font-size: 25px;
  font-weight: 700;
  color: black;
`
const Prop = styled.div`
  display: flex;
  border: 1px solid gray;
  justify-content: center;
  align-items: center;
  @media (max-width: 990px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  &:hover {
    background-color: #f0f0f0;
  }
`

const Item = styled.div`
  display: flex;
  padding: 5px;
  font-size: 12px;
  flex-wrap: wrap;
  overflow: hidden;
  height: auto;
  align-items: center;
  word-break: wrap;
  // color: black;
`

const InfoHeader = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  margin: 3px 10px 0 0;
  align-items: center;
`;

const InfoItem = styled.div`
  margin: 3px 0;
  display: flex;
  font-size: 14px;
  font-weight: 300;
  align-items: center;
`;

const Link =  styled.a`
  color: #3366CC;
`;

const BasicImage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  font-weight: 600;
  height: min(250px, 45vh);
  width: 90%;
  max-height: 500px;
  align-items: center;
  justify-content: center;
`

const UploadInput = styled.input`
  margin: 5px;
  text-align: center;
  opacity: 0;
  display: none;
`;

const Label = styled.label`
  color: #bf40bf;
  margin: 5px;
  background-color: #eee;
  font-size: 16px;
  flex-wrap: wrap;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
`;

const Row = styled.div`
  display: flex;
`;

const Span = styled.span`
    display: flex;
    align-items: center;
`;

export default DisplayListingComps