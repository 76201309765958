import React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import { Header } from 'utils/styledComps';
import InputComp from 'components/InputComp';
import TextboxComp from 'components/TextboxComp';
import Button from 'components/Button';
import SmallButton from 'components/SmallButton';
import axios from 'axios';
import { getFirstTuesdayOfMonth, monthNames, sleep } from 'utils/helperfunctions';
import { fetchWrapper } from 'utils/apihandlers'
import { clientLogger } from 'utils/clientLogger';
import HorizontalLine from 'components/horizontalLine/HorizontalLine';
import download from 'downloadjs'

interface Prop{
    county: string;
    auctiondate: string;
    openModal: (header: any, message: any, progress?: any) => void;
    closeModal: ()=>void;
}
const AddNewListing : React.FC<Prop>=({county, auctiondate, openModal, closeModal})=>{

    const [ street, setstreet ] = useState("")
    const [ city, setcity ] = useState("")
    const [ state, setstate ] = useState("TX")
    const [ zip, setzip ] = useState("")
    const [ legaldesc, setlegaldesc ] = useState("")
    const [ deednumber, setdeednumber ] = useState("")
    const [ beds, setbeds ] = useState("")
    const [ baths, setbaths ] = useState("")
    const [ improvementarea, setimprovementarea ] = useState("")
    const [ appraisedvalue, setappraisedvalue ] = useState("")
    const [ lienamount, setlienamount ] = useState("")
    const [ propertyid, setpropertyid ] = useState("")
    const [ noticelink, setnoticelink ] = useState("")
    const [ owner, setowner ] = useState("")
    const [ imageFileName, setImageFileName ] = useState("")
    const [ imageFile, setImageFile ] = useState<any>(null)
    const [ foreclosureid, setforeclosureid ] = useState("")

    const [ noticeFileName, setNoticeFileName ] = useState("")
    const [ noticeFile, setNoticeFile ] = useState<any>(null)
    const [ showdownloadfilelist, setshowdownloadfilelist ] = useState(false)
    
    const [ yearbuilt, setyearbuilt ] = useState("")
    const [ noticetext, setnoticetext ] = useState("")

    const [ startpageidx, setstartpageidx ] = useState("")
    const [ dividedinfo, setdividedinfo ] = useState<any>([])
    const [ displaynoticeidx, setdisplaynoticeidx ] = useState(-1)
    
    const [ noticefiles, setnoticefiles ] = useState<any>([])
    

    const addnotice = async () => {
        if(foreclosureid==""){
            openModal("Warning - Foreclosure id missing", "First select notice and parse to get foreclosure id")
            return
        }
        if(noticetext=="" || noticetext.length<50){
            openModal("Warning - foreclosure text is too short", "Notice text is short. Notice text is required to add notice")
            return
        }
        openModal("Adding Notice", "", true)
        let au_dt_arr = auctiondate.split("/")
        let fullauctiondate = getFirstTuesdayOfMonth(au_dt_arr[0], au_dt_arr[1])

        try{
            var response = await fetchWrapper.post("/api/foreclosureapi/addforeclosureprop", {foreclosureid:foreclosureid, lienamount: lienamount, appraisedvalue: appraisedvalue, improvementarea: improvementarea, ownername: owner, deednumber: deednumber, street: street, city: city, state: state, zip: zip, legaldescription: legaldesc, yearbuilt: yearbuilt, beds: beds, baths: baths, propertyid: propertyid, defaultamount: lienamount, county: county, auctiondate: fullauctiondate, foreclosurenotice:noticetext, noticeparsed: true,
                
            }).then(r=>r)
                openModal("Notice Added", "Notice added", false)
            }catch(error){
                console.log(`Error: ${error}`)
                
                openModal("Error", "Failed, probably dublicate notice", false)
            }
        }

    const clear = () => {
        setstreet("")
        setcity("")
        setstate("")
        setzip("")
        setlegaldesc("")
        setyearbuilt("")
        setdeednumber("")
        setbeds("")
        setbaths("")
        setimprovementarea("")
        setappraisedvalue("")
        setlienamount("")
        setpropertyid("")
        setnoticelink("")
        setowner("")
        setImageFileName("")
        setImageFile(null)
        setforeclosureid("")
        setnoticetext("")
        setdisplaynoticeidx(-1)
        setdividedinfo([])
    }

    const parsenoticetext = async () => {
        console.log(`county: ${county}`)
        
        if(!county || !county.toLowerCase().includes("dallas")){
            openModal("Warning", "Please select Dallas County", false)
            return
        }
        
        openModal("Uploading and reading file", "", true)
  
        var formData = new FormData()
  
        if (! noticeFile ) {
            openModal("Error", "Please select an notice file before proceeding.", false)
            return;
        }
  
        formData.append("file", noticeFile)
        let body = {
            county: county,
            auctiondate: auctiondate
        }
        formData.append("body", JSON.stringify(body))

        try{

            let response = await fetchWrapper.postFile('/api/foreclosureapi/uploadandparsenoticetxtpdf', formData).then((r: any)=>r)
            console.log(`response : ${JSON.stringify(response)}`)
            openModal("Message", response.saved_res, false)
            setNoticeFile(null)
            setNoticeFileName("")
            setnoticetext(response.text)
            setforeclosureid(response.id)
        }catch(error){
            console.log(error)
            openModal("Error", 'error posting file', false)
        }
    }

    const parsenoticeimage = async () => {

        openModal("Uploading and reading file", "", true)
  
        var formData = new FormData()
  
        if (! noticeFile ) {
          openModal("Error", "Please select an notice file before proceeding.", false)
            return;
        }
  
        formData.append("file", noticeFile)
  
        let response = await fetchWrapper.postFile('/api/foreclosureapi/uploadandparsenoticeimage', formData)
        openModal("Done!", "Notice parsed", false)
        setNoticeFile(null)
        setNoticeFileName("")
        setnoticetext(response.text)
        setforeclosureid(response.id)
    }

    const noticeFileChange = (event: any) => {
        if (!event.target.files || event.target.files[0] == undefined) {
            setNoticeFileName("")
            setNoticeFile(null)
        } else {
            if(!event.target.files[0].type.includes('pdf')){
                openModal("Warning", "Invalid file type, only pdf files are allowed.")
                return
            }
            setNoticeFileName(event.target.files[0].name)
            setNoticeFile(event.target.files[0])
        }
    }

    const itemImageChange = (event: any) => {
        if (!event.target.files || event.target.files[0] == undefined) {
            setImageFileName("")
            setImageFile(null)
        } else {
            if(!event.target.files[0].type.includes('image')){
                openModal("Warning", "Invalid file type, only images are allowed.")
                return
            }
            let size = parseFloat(event.target.files[0].size)
            if(size > 1000000){
                openModal("Warning", "File is too large, files should be less than 1mb")
                return
            }
            setImageFileName(event.target.files[0].name)
            setImageFile(event.target.files[0])
        }
    }
  
    const uploadimage = async () => {

        openModal("Uploading", "", true)
  
        var formData = new FormData()
  
        if (! imageFile ) {
          openModal("Error", "Please select an image before proceeding.", false)
            return;
        }
  
        formData.append("file", imageFile)
  
        formData.append("data", JSON.stringify({ id: foreclosureid}))
  
        await fetchWrapper.postFile('/api/foreclosureapi/uploadimage', formData)
        openModal("Done!", "Image uploaded", false)
        setImageFile(null)
        setImageFileName("")
    }

    const clearinputs = () => {
        setstartpageidx("")
    }

    const showprevious = () => {
        if(displaynoticeidx>0){
            let idx = displaynoticeidx-1
            setdisplaynoticeidx(idx)
            setnoticetext(dividedinfo[idx].noticetext)
            setforeclosureid(dividedinfo[idx].foreclosureid)
            setcity(dividedinfo[idx].city)
            setlienamount(dividedinfo[idx].lienamount?dividedinfo[idx].lienamount:"")
            setpropertyid("")
        }

    }

    const shownext = () => {
        if(displaynoticeidx<dividedinfo.length-1){
            let idx = displaynoticeidx+1
            setdisplaynoticeidx(idx)
            setnoticetext(dividedinfo[idx].noticetext)
            setforeclosureid(dividedinfo[idx].foreclosureid)
            setlienamount(dividedinfo[idx].lienamount?dividedinfo[idx].lienamount:"")
            setcity(dividedinfo[idx].city)
            setpropertyid("")
        }

    }

    const readnoticefile = async () => {

        console.log(`county: ${county}`)
        
        // if(!county || !county.toLowerCase().includes("dallas")){
        //     openModal("Warning", "Please select Dallas County", false)
        //     return
        // }

        if (! noticeFile ) {
            openModal("Error", "Please select an notice file before proceeding.", false)
            return;
        }

        if(startpageidx=="" || startpageidx.split(",").length<2){
            openModal("Warning", "Please enter page numbers", false)
            return
        }
        
        
        openModal("dividing file", "", true)
  
  
        if (! noticeFile ) {
            openModal("Error", "Please select an notice file before proceeding.", false)
            return;
        }
        setdisplaynoticeidx(-1)
        setdividedinfo([])
        
        let city = ""

        let apiname = ""
        switch(county){
            case "Dallas County":
                apiname = "uploadnoticedivideandparsepdftxt"
                city = noticeFileName.split("_")[0].split("-").join(" ")
                break
            case "Kaufman County":
            case "FortBend County":
                apiname = "uploadnoticedivideandparsepdfimage"
                break
            default:
                break
        }
        if(apiname==""){
            openModal("Message", `No Allowed`, false)
            return
        }
        let parsednotices: any = []
        
        setcity(city)
        let au_dt_arr = auctiondate.split("/")
        let fullauctiondate = getFirstTuesdayOfMonth(au_dt_arr[0], au_dt_arr[1])
        let pages_numbers = startpageidx.split(",")
        for(let i=0; i<pages_numbers.length-1; i++){
            let body = {
                county: county,
                auctiondate: fullauctiondate,
                pages: `${pages_numbers[i]},${pages_numbers[i+1]}`,
                city: city,
                noticefiletag: noticeFileName
            }
            openModal("Message", `Dividing Notice ${i+1} from page ${pages_numbers[i]}`, true)

            var formData = new FormData()
            formData.append("file", noticeFile)
            formData.append("body", JSON.stringify(body))
            try{
                let response = await fetchWrapper.postFile(`/api/foreclosureapi/${apiname}`, formData).then((r: any)=>r)
                // console.log(`response : ${JSON.stringify(response.noticeinfo[0])}`)
                parsednotices = [...parsednotices, ...response.noticeinfo]
                
            }catch(error){
                console.log(`${error}`)
                openModal("Warning", `${error}`, false)
                
            }
        }

        console.log(`parsednotices : ${JSON.stringify(parsednotices)}`)        

        setdividedinfo(parsednotices)
        if(parsednotices.length>0){
            let idx = 0
            setdisplaynoticeidx(idx)
            setnoticetext(parsednotices[idx].noticetext)
            setforeclosureid(parsednotices[idx].foreclosureid)
            setlienamount(parsednotices[idx].lienamount)
            setcity(parsednotices[idx].city)
        }
        openModal("Message", `done!`, false)
        setNoticeFile(null)
        setNoticeFileName("")
        setstartpageidx("")
        console.log(`done`)
    }

    const listfiles = async () => {
        setnoticefiles([])
        try{    
            let response: any = await fetchWrapper.post("/api/foreclosureapi/listnoticefiles", {month:`${monthNames[Number(auctiondate.split("/")[0])-1]}`, county: `${county.split(" ")[0].toLowerCase()}`}).then((r)=>r)
            if(response.data.status==200){
                setnoticefiles(response.data.files)
            }else{
                openModal("Error", response.data.msg, false)
            }
        }catch(error){
            console.log(`Error: ${error}`)
            openModal("Error", `${error}`, false)
        }
    }

    const downloadanyfile = async (filename: any) => {
        try{
            openModal("Downloading", "", true)
            let mth =`${monthNames[Number(auctiondate.split("/")[0])-1]}`
            var res = await fetchWrapper.getFile(`/api/downloadanyfile?county=${county.split(" ")[0].toLocaleLowerCase()}&filename=${mth}/${filename}`);
            var blob = await res.blob() 
            download(blob, filename)
            closeModal()
        }catch(error){
            console.log(`Error: ${error}`)
            openModal("Error", `${error}`, false)
        }
    }

    useEffect(()=>{
        setnoticefiles([])
    }, [county])

  
    

  return(
    <MainContainer>
        <Header>Add new listing</Header>

        <SubHeader>County: {county} </SubHeader>
        <SubHeader>Auction Date: {auctiondate} </SubHeader>

        <ButtonContainer>
            <Button text="list files" handleClick={()=>{listfiles()}} />
            <Button text="hide" handleClick={()=>{setnoticefiles([])}} />
        </ButtonContainer>

        {
            noticefiles.map((v: any, id: any) => (
                <FileLink onClick={()=>downloadanyfile(v)}>{v}</FileLink>
            ))
        }

        <ButtonContainer>
            <InputComp id="streetid" value={street} onChange={(e:any)=>setstreet(e.target.value)} placeholder={"street"} width={250}/>
            <InputComp id="cityid" value={city} onChange={(e:any)=>setcity(e.target.value)}  placeholder={"city"} width={150}/>
            <InputComp id="stateid" value={state} onChange={(e:any)=>setstate(e.target.value)}  placeholder={"state"} width={50}/>
            <InputComp id="zipid" value={zip} onChange={(e:any)=>setzip(e.target.value)} placeholder={"zip"} width={75}/>
            <InputComp id="legal" value={legaldesc} onChange={(e:any)=>setlegaldesc(e.target.value)} placeholder={"legal description"} width={300}/>
            <InputComp id="yearbuiltid" value={yearbuilt} onChange={(e:any)=>setyearbuilt(e.target.value)} placeholder={"year built"} width={75}/>

            <InputComp id="beds" value={beds} onChange={(e: any)=>setbeds(e.target.value)} width={75} placeholder='beds'/>
            <InputComp id="baths" value={baths} onChange={(e: any)=>setbaths(e.target.value)} width={75} placeholder='baths'/>
            <InputComp id="improvement area" value={improvementarea} onChange={(e: any)=>setimprovementarea(e.target.value)} width={100} placeholder='improvementarea'/>
            <InputComp id="appraisedvalue" value={appraisedvalue} onChange={(e: any)=>setappraisedvalue(e.target.value)} width={100} placeholder='appraised value'/>
            <InputComp id="lienamount" value={lienamount} onChange={(e: any)=>setlienamount(e.target.value)} width={75} placeholder='lien amount'/>
            <InputComp id="propertyid" value={propertyid} onChange={(e: any)=>setpropertyid(e.target.value)} width={75} placeholder='property id'/>
            <InputComp id="owner" value={owner} onChange={(e: any)=>setowner(e.target.value)} width={250} placeholder='owner name'/>
            <InputComp id="noticelink" value={noticelink} onChange={(e: any)=>setnoticelink(e.target.value)} width={250} placeholder='notice link'/>
            <InputComp id="noticelink" value={foreclosureid} onChange={(e: any)=>{}} width={150} placeholder='foreclosure id'/>

        </ButtonContainer>

        <TextboxComp id="noticetext" placeholder='Notice' value={noticetext} onChange={(e:any) => {setnoticetext(e.target.value)}} cols={50} rows={50} width={500}/>

        <div style={{display: 'flex', flexDirection:'column'}}>
        {/* <ManualDownloadNotice county={county} auctiondate={auctiondate} openModal={openModal} noticeFile={noticeFile} /> */}
        <Span >
            <UploadInput type="file" id="inputfilename"
                onChange={noticeFileChange}
            />
            <Label htmlFor='inputfilename' style={{ padding:"10px", color:"#33813B", backgroundColor:"#eee"}}>Select Notice File </Label>
            <SmallButton text="parse text" handleClick={parsenoticetext} />
            <SmallButton text="parse Image" handleClick={parsenoticeimage} backgroundColor={"#813379"}/>
        </Span>
        <div style={{padding:"7px", color: "#813379"}}>{noticeFileName}</div>

        </div>

        <SubHeader>Divide Notices</SubHeader>
            <InputComp id="pagesinfileid" placeholder="start page #s: 1,3,7,lastpage+1" onChange={(e: any)=>setstartpageidx(e.target.value)} value={startpageidx} width={500}/>
            {
                dividedinfo.length>0?<ButtonContainer>
                    notice# {displaynoticeidx+1}
                    <SmallButton text='previous' handleClick={showprevious} />
                    <SmallButton text='next' handleClick={shownext} />
                </ButtonContainer>:<></>
            }
            <ButtonContainer>
                <SmallButton text="divide file" handleClick={readnoticefile} />
                <SmallButton text="clear" handleClick={clearinputs} />
            </ButtonContainer>
        <HorizontalLine />
        <ButtonContainer>
            <Button text={"ADD"} handleClick={()=>addnotice()} />
            <Button text={"Clear"} handleClick={()=>clear()} backgroundColor="#813379"/>
        </ButtonContainer>

        {/* <Span>
        <Label htmlFor="itemimage" style={{ color: "#33813B", margin: "5px" }}>
            Select Image
        </Label>
        <div>{imageFileName}</div>
        <UploadInput
            type="file"
            id="itemimage"
            onChange={itemImageChange}
        />
        <SmallButton text="upload" handleClick={uploadimage} />
        </Span> */}
    </MainContainer>
  );
}

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
`;

const Span = styled.span`
    display: flex;
    align-items: center;
`;

const SubHeader = styled.div`
    font-weight: 700;
    font-size: 20px;
`;
const Label = styled.label`
  color: #bf40bf;
  margin: 5px;
  background-color: #eee;
  font-size: 16px;
  flex-wrap: wrap;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
`;

const UploadInput = styled.input`
  margin: 5px;
  text-align: center;
  opacity: 0;
  display: none;
`;

const FileLink = styled.div`
  margin: 7px;
  cursor: pointer;
  color: #3366CC;
`;

export default AddNewListing